import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    StatusComponentCreator,
    BooleanExcelDisplayCreator,
    TableFieldDescription,
    GeneralChipComponentCreator,
    GeneralExcelDisplayCreator,
    PasswordComponentCreator,
    WebCredentialSelectedActions
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllWebCredentials(
        $accounts: String, 
        $url: String, 
        $password: String, 
        $comment: String, 
        $identifier: String,
        $isActive: Boolean
    ) {
        allWebCredentials(
            accounts: $accounts, 
            url: $url, 
            password: $password, 
            comment: $comment, 
            identifier: $identifier,
            isActive: $isActive
        ) {
            edges {
                node {
                    id
                    url
                    identifier
                    password
                    comment
                    isActive
                    accountSet {
                        edges{
                            node{
                                id
                                identifier
                                provider{
                                    name
                                }
                            }
                        }

                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editWebCredentialMutation(
        $id: String!, 
        $accountSet: [Int], 
        $url: String, 
        $password: String, 
        $comment: String, 
        $identifier: String,
        $isActive: Boolean,
    ) {
        editWebCredential(
            id: $id, 
            accountSet: $accountSet, 
            url: $url, 
            password: $password, 
            comment: $comment, 
            identifier: $identifier,
            isActive: $isActive,
        ) {
            webCredential {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createWebCredentialMutation(
        $accountSet: [Int], 
        $url: String, 
        $password: String,
        $comment: String, 
        $identifier: String,
        $isActive: Boolean,
    ) {
        createWebCredential(
            accountSet: $accountSet, 
            url: $url, 
            password: $password, 
            comment: $comment, 
            identifier: $identifier,
            isActive: $isActive,
        ) {
            webCredential {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeWebCredentialMutation($ids: [String!], $allRecords: Boolean, $filters: String, $phisicalDelete: Boolean) {
        removeWebCredential(ids: $ids, allRecords: $allRecords, filters: $filters, phisicalDelete: $phisicalDelete) {
            webCredential {
                id
            }
        }
    }
`

const unitQuery = `
    webCredential(id: $id) {
        id
        password
        url
        comment
        identifier
        isActive
        accountSet {
            edges{
                node{
                    id
                    identifier
                    provider{
                        id
                        name
                    }
                }
            }
        }
    }
`

const WebCredentialsTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class WebCredentialsTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "WebCredentialsTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, WebCredentialsTableCreatorQueries)
        tableCreator.setSelectedRowActionsStrategy(new WebCredentialSelectedActions(deleteMutator))
        tableCreator.setAdditionalFilters({first:30})
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'identifier',
                new DataMapper('identifier'),
                new f.FilterDescription(new f.TextFilter('identifier')),
                {label: 'Web Login'}
            ),
            new TableFieldDescription(
                'url',
                new DataMapper('url'),
                new f.FilterDescription(new f.TextFilter('url')),
                {label: 'Url'}
            ),
            new TableFieldDescription(
                'password',
                new DataMapper('password'),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Password', hideSort:true, componentCreator: new PasswordComponentCreator()}
            ),
            new TableFieldDescription(
                'comment',
                new DataMapper('comment'),
                new f.FilterDescription(new f.TextFilter('comment')),
                {label: 'Comment'}
            ),
            new TableFieldDescription(
                'isActive',
                new DataMapper('isActive'),
                new f.FilterDescription(new f.BooleanFilter('isActive')),
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'accounts',
                new DataMapper("accountSet"),
                new f.FilterDescription(new f.TextFilter("accounts")),
                {label: 'Accounts', hideSort:true, 
                excelDisplay : new GeneralExcelDisplayCreator('Account', [{label: 'ID', path: 'identifier'}]),
                componentCreator: new GeneralChipComponentCreator(["identifier"],["identifier"], null, false),
                transform: new DataTransformer(({accountSet})=>(accountSet.edges.map(data => data.node))),}
            ),
        ])

        return tableCreator
    }
}
    
tableCreatorCreatorFactoryCreator.addTableCreatorCreator(WebCredentialsTableCreator)
    
export {
    WebCredentialsTableCreator,
    WebCredentialsTableCreatorQueries
}
    
